<template>
    <div>
        <div class="columns is-mobile is-multiline">
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Name') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('Name')" v-model="banner.name">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('State') }}</label>
                    <div class="control">
                        <input type="checkbox" v-model="isActive" @click="toggleCheckboxes">
                        {{ i18n(isActive ? 'Active' : 'Inactive') }}
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Select Program') }}</label>
                    <vue-select :source="route('programs.options')"
                                v-model="banner.program"
                                :http="http"
                                track-by="name"
                                :placeholder="i18n('Pick an option')"/>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Property') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('UserProperty')"
                               v-model="banner.userProperty">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Segmentation') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('Segmentation')"
                               v-model="banner.segmentation">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Position') }}</label>
                    <div class="control">
                        <input class="input" type="number"
                               :placeholder="i18n('Position')" v-model="banner.position">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Video Link') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('Video Link')" v-model="banner.videoLink">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Image Link') }}</label>
                    <div class="control">
                        <input class="input" type="text"
                               :placeholder="i18n('Image Link')" v-model="banner.imageLink">
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile is-half-tablet">
                <div class="field">
                    <label class="label">{{ i18n('Description') }}</label>
                    <div class="control">
                        <textarea class="textarea" :placeholder="i18n('Description')"
                                  v-model="banner.description" maxlength="800"/>
                    </div>
                </div>
            </div>
            <div class="column is-full-mobile">
                <div class="field">
                    <label class="label">{{ i18n('Choose an File') }}</label>
                    <div class="control">
                        <label class="file-label">
                            <input class="file-input" type="file"
                                   accept=".pdf, .jpg, .png, .webp" @change="handleFileUpload">
                            <span class="file-cta">
                                <span class="file-label">
                                    {{ i18n('Choose an File') }}...
                                </span>
                            </span>
                            <span class="file-name">
                                {{ fileName || i18n('No file chosen') }}
                            </span>
                        </label>
                        <p class="letterFileSize">
                            {{ i18n('Maximum file size') }}: 18 MB.
                        </p>
                    </div>
                </div>
            </div>
        </div>
        <a class="is-full-mobile is-half-tablet button-alignment"
           @click="$router.push('/banner')">
            <button class="button butt-return">
                {{ i18n('Back') }}
                <fa class="i-icon" icon="arrow-left"/>
            </button>
        </a>
        <a class="is-full-mobile is-half-tablet button-alignment"
           @click="fetchUpdate">
            <button class="button butt-view">
                {{ i18n('Update') }}
                <fa class="i-icon" icon="check"/>
            </button>
        </a>
        <a class="is-full-mobile is-half-tablet button-alignment"
           @click="fetchDelete">
            <button class="button butt-delete">
                {{ i18n('Delete') }}
                <fa class="i-icon" icon="trash-alt"/>
            </button>
        </a>
    </div>
</template>

<script>
import { FontAwesomeIcon as Fa } from '@fortawesome/vue-fontawesome';
import { VueSelect } from '@enso-ui/select/bulma';

export default {
    name: 'Edit',

    components: { Fa, VueSelect },

    inject: ['http', 'i18n', 'errorHandler', 'route'],

    data: () => ({
        banner: {},
        isActive: false,
        programs: {},
        fileName: '',
    }),

    mounted() {
        this.fetch();
    },

    methods: {
        fetch() {
            this.http.get(this.route('banner.show', this.$route.params.banner))
                .then(({ data }) => {
                    this.banner = data.banner;
                    this.isActive = data.banner.state === 'Active';
                }).catch(this.errorHandler);
        },
        fetchDelete() {
            this.http.delete(this.route('banner.destroy', this.$route.params.banner))
                .then(() => {
                    this.$router.push('/banner');
                }).catch(this.errorHandler);
        },
        handleFileUpload(event) {
            // eslint-disable-next-line prefer-destructuring
            this.banner.file = event.target.files[0];
            this.fileName = event.target.files[0].name;
        },
        fetchUpdate() {
            const formData = new FormData();
            formData.append('file', this.banner.file);
            formData.append('id', this.banner.id);
            formData.append('name', this.banner.name);
            formData.append('state', this.isActive ? 'Active' : 'Inactive');
            formData.append('program', this.banner.program);
            formData.append('userProperty', this.banner.userProperty);
            formData.append('segmentation', this.banner.segmentation);
            formData.append('position', this.banner.position);
            formData.append('imageLink', this.banner.imageLink);
            formData.append('videoLink', this.banner.videoLink);
            formData.append('description', this.banner.description);
            const headers = { 'Content-Type': 'multipart/form-data' };
            this.http.post(this.route('banner.updateFile'), formData,
                { headers })
                .then(() => {
                    this.$router.push('/banner');
                }).catch(this.errorHandler);
        },
        toggleCheckboxes() {
            this.isActive = !this.banner.state === 'Active';
            this.banner.state = !this.banner.state === 'Active' ? 'Active' : 'Inactive';
        },
    },
};
</script>

<style lang="scss">
@import '../../../sass/enso.scss';

.butt-return{
    background-color: $orange;
    border-color: transparent;
    color: $secondary !important;
}

.i-icon {
    margin-left: 5px;
}

.butt-delete{
    background-color: $red;
    border-color: transparent;
    color: $secondary !important;
}

.butt-view{
    background-color: $green;
    border-color: transparent;
    color: $secondary !important;
}

.button-alignment{
    padding-left: 7px;
    padding-right: 7px;
    padding-top: 40px;
}

.styleInput{
    background-color: transparent;
    border-color: transparent;
}

.letterFileSize{
    font-size: small;
    color: $greyToneEleven;
}

</style>
